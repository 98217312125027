import { Frame, Layout, Loading, Page } from '@shopify/polaris';
import React, { useState, useEffect} from 'react';
import { restApi } from '../utils/session';

const Home = () => {
  const [customerData, setCustomerData] = useState(null as any);
  const [customerId] = useState(new URLSearchParams(window.location.search).get('id'));

  useEffect(() => {
    restApi(`/customers/${customerId}.json`).then(data => {
      setCustomerData(data);
      restApi(`/multipass.json?email=${encodeURIComponent(data.customer.email)}`).then(multipassData => {
        // window.location = multipassData.url;
        console.log(multipassData);
        if (multipassData && multipassData.url) {
          if (window.top) {
            window.top.location = multipassData.url;
          } else {
            window.location = multipassData.url;
          }
        }
      });
    });
  }, [])
  return (
    {customerData} ? (
      <Page>
        <Layout>
        </Layout>
      </Page>
    ): (
      <div style={{height: '100px'}}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    )
  )
}

export default Home;